import React from "react";
import "../../features.css";

function Features() {
  const features = [
    {
      title: "Read Aloud",
      description: "Learn to Read fluently!",
      icon: "📖",
    },
    {
      title: "Memory Challenge",
      description: "Test your comprehension!",
      icon: "🧠",
    },
    {
      title: "Story Time",
      description: "Explore magical stories and adventures!",
      icon: "✨",
    },
    {
      title: "Spelling Bee",
      description: "Master new words with exciting spelling challenges!",
      icon: "🐝",
    },
    {
      title: "Generative AI",
      description: "Use AI to create stories!",
      icon: <img src="generative-ai.png" alt="Generative AI Icon" className="custom-icon" /> 
    },
    {
      title: "Create Your Own",
      description: "Have creative flair, create your own stories",
      icon: <img src="lightbulb.png" alt="Light Bulb Icon" className="custom-icon" />
    }, 
    {
      title: "Virtual Assistants",
      description: "A friendly buddy to help you out",
      icon: <img src="koala.png" alt="Virtual Assistant Icon" className="custom-icon" />
    },
    {
      title: "Play from anywhere",
      description: "No internet connection required to play",
      icon: "🌍"
    }
  ];

  return (
    <section id="features" className="features">
      <h2>Features</h2>
      <div className="feature-cards">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;
