import React from "react";
import "../../hero.css";

function hero() {
  return (
    <section className="hero">
      <div className="hero-image">
          <img src="splashscreen_image.png"  alt="Readify logo" />
      </div>
      <h1>Discover the Magic of Language with Readify!</h1>
      <p>Learn to Read and Comprehend with AI, Voice Recognition and a friendly buddy</p>
      
      <div className="hero-buttons">
        <a href="https://play.google.com/store/apps/details?id=org.jadder.readify"><img class="google" alt="Get it on Google Play" src="google.png" /></a>
        <a href="https://apps.apple.com/au/app/readify/id6478643928"><img class="apple" alt="Get it on IOS" src="apple.png" /></a>
      </div>
    </section>
  );
}

export default hero;
