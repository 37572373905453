import React, { Component } from 'react';
import Hero from '../pages/hero';
import Footer from '../footerComponent/footer';

class About extends Component {
  render() {
    return (
      <div class="home">
        <Hero />
       <h1 class="title">Our Story</h1>
        <p>We believe reading should be a joyful and accessible journey for everyone. Our story began with a simple yet profound mission: to help an autistic nephew and his grandmother connect and learn to read together over Zoom. What started as a personal endeavor has grown into a heartfelt platform designed to make learning to read engaging, inclusive, and fun for learners of all ages and abilities.</p>
        <p>Readify combines innovative technology with thoughtful, research-based methods to create a safe and supportive environment for developing reading skills. Whether you’re a child exploring the magic of books for the first time or an adult improving their memory and spelling, our tools and resources are here to guide you every step of the way.</p>
        <p>Rooted in Australian values of inclusivity and innovation, Readify combines cutting-edge technology with evidence-based methods to create a safe and supportive environment for developing reading skills. Whether it’s a child unlocking the magic of books for the first time or an adult rediscovering the power of stories, Readify is here to help every step of the way.</p>
        <p>We’re more than just an app—we’re a community that celebrates progress, fosters connection, and empowers learners to unlock their full potential.  Welcome to Readify, where Australian ingenuity helps bring stories to life, one word at a time.</p>
        <Footer />
      </div>
    )
  }
}

export default About;