import React, { Component } from 'react';
import Hero from '../pages/hero';
import Footer from '../footerComponent/footer';


class Privacy extends Component {
  render() {
    return (
      <div>
      <Hero />
      <div className="main">
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
            <p>This Privacy policy applies to each company listed in the Companies section at the base of this document ("Company", "us" or "we"), it explains how we collection, use and dislose information about you.</p>
            <p>This Privacy policy applied to information which identifies you, or from which you can be reasonably be identified, ie: your device id</p>
        <h2>Why do i have to subscribe?</h2>
            <p>In order to get full access to create stories and export them to other devices</p>
        <h2>How is my subscription managed?</h2>
            <p>Google and Apple mantain your subscription, there is a third party (RevenueCat) that validates between OS provider and the App to ensure these subscriptions provide the correct access.</p>    
        <h2>How do we protect your information?</h2>
            <p>We take reasonable steps to prevent unauthorised access to our online and computerised systems and by measures such as firewalls, data encryption, virus deteticion methods</p>
            <p>Never store your passwords or key identifying information within this app ie: bank account numbers etc.</p>
            <p>The app stores all its data locally but in an unencrypted format.</p>
        <h2> Companies </h2>
        <p>Jadder Development House, the developer of Readify</p>
     
      </div>
       
      <Footer />
      </div>
    )
  }
}

export default Privacy;