import React from "react";
import "../../footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p>All Rights Reserved. Copyright 2024 Powered by Jadder Development House. </p>
      <nav>
        <a href ="/">Home</a>|<a href="About">About Us</a>|<a href="privacy">Privacy Policy</a> | <a href="terms">Terms of Use</a>| <a href="faq">Frequently Asked Questions</a>|<a href="contact">Contact</a>
      </nav>
    </footer>
  );
}

export default Footer;
